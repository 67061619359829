import React, { useEffect, useState } from 'react'
import { styles } from './styles'
import chirpyestLogo from './../../assets/images/chirpyest-logo.svg'
import chirpyestTextLogo from './../../assets/images/chirpyest-text-logo.svg'
import { ROUTES } from '../../constants'
import ContentContainer from './../contentContainer'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { Link } from 'gatsby'
import { isUserAuthenticated } from './../../../utils/isUserAuthenticated'
import { useAppState } from '../../appState'

interface CHRHeaderProps {
  t: TFunction
}

const CHRHeader = ({ t }: CHRHeaderProps) => {
  const classes = styles()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [, dispatch] = useAppState()

  useEffect(() => {
    isUserAuthenticated().then(res => {
      setIsLoggedIn(res)
    })
  }, [])

  return (
    <header className={classes.header}>
      <div className={classes.sloganBanner}>
        <h2 className={classes.sloganTitle}>
          {t('shared.shop')} + {t('shared.earnCashBack')} -{' '}
          {t('shared.chirpChirp')}
        </h2>
      </div>
      <ContentContainer>
        <nav className={classes.navigation}>
          {/*---------------- LEFT NAVIGATION ----------------*/}
          <div className={classes.navLeft}>
            <Link
              rel="preconnect"
              to={isLoggedIn ? ROUTES.member : ROUTES.home}
              className={`${classes.chirpyestLogoLink} ${classes.listItem}`}
            >
              <img
                src={chirpyestLogo}
                alt={t('shared.chirpyest')}
                className={classes.chirpyestLogo}
              />
            </Link>
          </div>
          {/*---------------- CENTER NAVIGATION ----------------*/}
          <div className={classes.navCenter}>
            <Link
              rel="preconnect"
              to={isLoggedIn ? ROUTES.member : ROUTES.home}
            >
              <img
                src={chirpyestTextLogo}
                alt={t('shared.chirpyest')}
                className={classes.chirpyestTextLogo}
              />
            </Link>
          </div>
        </nav>
      </ContentContainer>
    </header>
  )
}

export default withTranslation()(CHRHeader)
